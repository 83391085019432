
var VitoRegister = function () {
    return {
        registerform: null,
        getForm: function () {
            return this.registerform;
        },
        init: function (formselector) {
            if (formselector === undefined) {
                this.registerform = $('#vt-register-form')
            } else {
                this.registerform = $(formselector);
            }

            this.addListener();
            this.bindRedirects();
        },
        addListener: function () {
            var parent = this;
            this.registerform.on('submit', function (event) {
                event.preventDefault();
                parent.sendRequest();

            })
        },
        sendRequest: function () {
            parent = this;
            console.log(this.registerform)
            url = this.registerform.attr('action')
            method = this.registerform.attr('method')

            form_data = {
                email: this.registerform.find('.vt-email').val(),
                username: this.registerform.find('.vt-username').val(),
                  lastname: this.registerform.find('.vt-lastname').val(),
                 name: this.registerform.find('.vt-name').val(),
                password: this.registerform.find('.vt-password').val(),
                password_confirmation: this.registerform.find('.vt-password_confirmation').val(),
            }

            console.log(form_data)

            $.ajax({
                url: url,
                type: method,
                data: form_data,
                success: function (msg) {
                    console.log(msg);
                    if (msg.success === true) {
                        SuccessWriter.write(msg.message, true);
                        parent.registerform.find('[type=submit]').prop('disabled', true)
                    } else {
                        ErrorWriter.write(msg.message, true)
                    }

                },
                error: function (msg) {
                    if (msg.status === 422) {
                        req_errors = $.parseJSON(msg.responseText)
                        ErrorWriter.cleanMessages();
                        for (var property in req_errors) {
                            if (req_errors.hasOwnProperty(property)) {
                                for (key in req_errors[property]) {
                                    message = req_errors[property][key];
                                    ErrorWriter.write(message)
                                }
                            }
                        }
                    }
                }
            });
        },
        bindRedirects: function () {
            var login_form = this.registerform;
            this.registerform.find('.vt-login-link').on('click', function (e) {
                e.preventDefault();

                FormSwitcher.showForm(VitoLogin.getForm())
            });
        },
        showInitial: function () {
            FormSwitcher.showForm(VitoRegister.getForm())
        }
    }
}();