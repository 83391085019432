var ErrorWriter = function () {
    return {
        getVisibleForm: function(){
          return $('.vt-form:visible');  
        },
        write: function (message, delete_prev) {
            visible_form = this.getVisibleForm();
            if (delete_prev === true) {
                this.cleanMessages()
            }

            visible_form.find('.error-form').append('<p>' + message + '</p>')
        },
        cleanMessages: function () {
            visible_form = this.getVisibleForm();
            visible_form.find('.error-form').html('')
        }
    }
}();

var SuccessWriter = function () {
    return {
        getVisibleForm: function(){
          return $('.vt-form:visible');  
        },
        write: function (message, delete_prev) {
            visible_form = this.getVisibleForm();
            if (delete_prev === true) {
                this.cleanMessages()
            }

            visible_form.find('.success-form').append('<p>' + message + '</p>')
        },
        cleanMessages: function () {
            visible_form = this.getVisibleForm();
            visible_form.find('.success-form').html('')
        }
    }
}();