
var VitoForgotPass = function () {
    return {
        forgotpassform: null,
        getForm: function(){
        //    console.log(this.forgotpassform)
            return this.forgotpassform;
        },
        init: function (formselector) {
            if (formselector === undefined) {
                this.forgotpassform = $('#vt-forgetpass-form')
            } else {
                this.forgotpassform = $(formselector);
            }

            this.addListener();
       //     console.log(this.forgotpassform)
            this.bindRedirects();
        },
        addListener: function () {
            var parent = this;
            this.forgotpassform.on('submit', function (event) {
                event.preventDefault();
                parent.sendRequest();

            })
        },
        sendRequest: function () {
          forgot_form = this.forgotpassform;
            url = this.forgotpassform.attr('action')
            method = this.forgotpassform.attr('method')

            form_data = {
                email: this.forgotpassform.find('.vt-email').val(),
            }

           
            $.ajax({
                url: url,
                type: method,
                data: form_data,
                success: function (msg) {
                    console.log(msg);
                    if (msg.success === true) {          
                     SuccessWriter.write(msg.message, true)
                                 forgot_form.find('[type=submit]').prop('disabled', true);           
                    } else {
                       ErrorWriter.write(msg.message, true)
                    }

                },
                error: function (msg) {
                    console.log(msg)
                    if (msg.status === 422) {
                        req_errors = $.parseJSON(msg.responseText)
                        ErrorWriter.cleanMessages();
                        for (var property in req_errors) {
                            if (req_errors.hasOwnProperty(property)) {
                                for (key in req_errors[property]) {
                                    message = req_errors[property][key];
                                    ErrorWriter.write(message)
                                }
                            }
                        }
                    }
                }
            });
        },
        bindRedirects: function(){
            var forgotpass_form = this.forgotpassform;
            this.forgotpassform.find('.vt-login-link').on('click', function(e){
                e.preventDefault();
                  FormSwitcher.showForm(VitoLogin.getForm())
            });
        },
       
    }
}();