
var VitoLogin = function () {
    return {
        loginform: null,
        getForm: function () {
            return this.loginform;
        },
        init: function (formselector) {
            if (formselector === undefined) {
                this.loginform = $('#vt-login-form')
            } else {
                this.loginform = $(formselector);
            }

            this.addListener();
            this.bindRedirects();
        },
        addListener: function () {
            var parent = this;
            this.loginform.on('submit', function (event) {
                event.preventDefault();
                parent.sendRequest();

            })
        },
        sendRequest: function () {
            console.log(this.loginform)
            url = this.loginform.attr('action')
            method = this.loginform.attr('method')

            form_data = {
                email: this.loginform.find('.vt-email').val(),
                password: this.loginform.find('.vt-password').val(),
            }

            console.log(form_data)

            $.ajax({
                url: url,
                type: method,
                data: form_data,
                success: function (msg) {
                    console.log(msg);
                    if (msg.success === true) {
                        if (msg.redirect) {
                            ErrorWriter.write('', true);
                            window.location.href = msg.redirect;
                        }
                    } else {
                        ErrorWriter.write(msg.message, true)
                    }

                },
                error: function (msg) {
                    $('body').append(msg) 
                    if (msg.status === 422) {
                        req_errors = $.parseJSON(msg.responseText)
                        ErrorWriter.cleanMessages();
                        for (var property in req_errors) {
                            if (req_errors.hasOwnProperty(property)) {
                                for (key in req_errors[property]) {
                                    message = req_errors[property][key];
                                    ErrorWriter.write(message)
                                }
                            }
                        }
                    }
                }
            });
        },
        bindRedirects: function () {
            var login_form = this.loginform;
            this.loginform.find('.vt-forgotpass-link').on('click', function (e) {
                e.preventDefault();

                FormSwitcher.showForm(VitoForgotPass.getForm())
            });
             this.loginform.find('.vt-register-link').on('click', function (e) {
                e.preventDefault();

                FormSwitcher.showForm(VitoRegister.getForm())
            });
        },
        showInitial: function () {
            hash = window.location.hash;
            console.log(hash)
            if (hash === '#forgotpass') {
                FormSwitcher.showForm(VitoForgotPass.getForm());
            } else if(hash ==='#register'){
                FormSwitcher.showForm(VitoRegister.getForm());
            } else {
                FormSwitcher.showForm(VitoLogin.getForm())
            }
        }
    }
}();