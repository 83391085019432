$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});




var FormSwitcher = function () {
    return {
        showForm: function (form) {
            $('.vt-form').hide();
            form.fadeIn();
        }
    }
}();

var VitoAuth = function () {
    return {
        init: function () {
            VitoLogin.init()
            VitoForgotPass.init();
            VitoResetPass.init();
            VitoRegister.init();

            this.loaderBind();
        },
        loaderBind: function () {
            $(document).ajaxStart(function () {
                $('.vt-loader').fadeIn();
            });
               $(document).ajaxStop(function () {
                $('.vt-loader').fadeOut();
            });
            
        }
    }
}();
